import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

const PartnershipContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const PartnershipHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const PartnershipSubheading = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));

const Partnership = () => {
  return (
    <PartnershipContainer maxWidth="md">
      <PartnershipHeading variant="h4" align="center">
        Partner with Us
      </PartnershipHeading>
      <Typography variant="body1" paragraph>
        AI Outfit Generator receives over 10,000 monthly visitors seeking fashion inspiration and outfit ideas. By
        partnering with us, you can showcase your brand and products to a targeted audience.
      </Typography>
      <PartnershipSubheading variant="h5">Do You Have a Fashion Site?</PartnershipSubheading>
      <Typography variant="body1" paragraph>
        If you own a fashion website or sell clothing and accessories online, partnering with AI Outfit Generator can
        help you reach a wider audience. Our AI-powered outfit suggestions feature products from various brands, driving
        traffic and sales to our partners' websites.
      </Typography>
      <PartnershipSubheading variant="h5">Promote Your Site</PartnershipSubheading>
      <Typography variant="body1" paragraph>
        As a partner, your products will be featured in our outfit suggestions, exposing your brand to thousands of
        potential customers. You'll also have the opportunity to promote your website through sponsored content and
        targeted ads on our platform.
      </Typography>
      <Typography variant="body1" paragraph>
        To learn more about partnership opportunities, please email us at harikezdigital@gmail.com.
      </Typography>
    </PartnershipContainer>
  );
};

export default Partnership;