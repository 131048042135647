import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App'; // Import the App component
import Party from './pages/men/Party';
import Header from './Header';
import Footer from './components/Footer';
import Privacy from './pages/Privacy';
import About from './pages/About';
import Partnership from './pages/Partnership';
import Contact from './pages/Contact';

const AppRoutes = () => {
  return (
    <Router>
      <Header /> {/* Render the Header component */}
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/men/party" element={<Party />} />
        {/* ... add other category routes */}
      </Routes>
    </Router>
  );
};

export default AppRoutes;