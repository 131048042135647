import React, { useState } from 'react';
import outfitData from './data/outfitData';
import Footer from './components/Footer';
import { Analytics } from "@vercel/analytics/react"
import { styled, createTheme } from '@mui/material/styles';
import { getAbsoluteUrl } from './utils';
import { shuffle } from 'lodash';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  Container,
  Grid,
  Button,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  IconButton,
  Collapse,
  Avatar,
  Chip,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';

// Styles using emotion
const GenderButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  width: 200,
  height: 200,
}));

const ShopCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const RelatedCategoriesSection = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
}));

const RelatedCategory = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const RelatedCategoryAvatar = styled(Avatar)(({ theme }) => ({
  width: 100,
  height: 100,
  marginBottom: theme.spacing(1),
}));

const ShareSection = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const OccasionCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: theme.spacing(2),
}));

const ColorChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const OutfitCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: theme.spacing(2),
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '100%',
    margin: theme.spacing(1),
  },
}));

const OutfitImage = styled('img')(({ theme }) => ({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  [theme.breakpoints.down('sm')]: {
    maxHeight: '50vh', // Adjust the value as needed
  },
}));

const OutfitOverlay = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '1.5rem',
  fontWeight: 'bold',
  opacity: 0,
  transition: 'opacity 0.3s ease',
}));

const GenerateButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

const ResetButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const HeaderAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  height: 64,
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  textAlign: 'center',
}));

const HeaderMenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderAppBar position="static">
      <Toolbar>
        <HeaderTitle variant="h6">AI Outfit Generator</HeaderTitle>
        <HeaderMenuButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </HeaderMenuButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMenuClose}>About Us</MenuItem>
          <MenuItem onClick={handleMenuClose}>Privacy</MenuItem>
          <MenuItem onClick={handleMenuClose}>Partnership</MenuItem>
        </Menu>
      </Toolbar>
    </HeaderAppBar>
  );
};

const App = () => {
  const [step, setStep] = useState(1);
  const [sex, setSex] = useState('');
  const [occasion, setOccasion] = useState('');
  const [selectedColors, setSelectedColors] = useState([]);
  const [outfitSuggestions, setOutfitSuggestions] = useState([]);
  const [selectedOutfit, setSelectedOutfit] = useState(null);

  const relatedCategories = [
    { key: 'Casual', label: 'Casual', image: 'https://i.ibb.co/WvbftCD/casu.png' },
    { key: 'Formal', label: 'Formal', image: 'https://i.ibb.co/QpbYFMH/foru.png' },
    { key: 'Wedding', label: 'Wedding', image: 'https://i.ibb.co/3B0HjMK/wedd.png' },
    { key: 'Beach', label: 'Beach', image: 'https://i.ibb.co/Z2XXLWW/beach.png' },
    { key: 'Party', label: 'Party', image: 'https://i.ibb.co/CzfjRcg/party.png' },
  ];

  const maleOccasions = [
    { key: 'Party', label: 'Party', image: 'https://i.ibb.co/CzfjRcg/party.png' },
    { key: 'Beach', label: 'Beach', image: 'https://i.ibb.co/JRxVMXH/be.png' },
    { key: 'Wedding', label: 'Wedding', image: 'https://i.ibb.co/3B0HjMK/wedd.png' },
    { key: 'College', label: 'College', image: 'https://i.ibb.co/wSgMc7w/coll.png' },
    { key: 'Office', label: 'Office', image: 'https://i.ibb.co/5FSMzxj/for.png' },
    { key: 'Brunch', label: 'Brunch', image: 'https://i.ibb.co/6v3JKNp/bru.png' },
  ];

  const femaleOccasions = [
    { key: 'College', label: 'College', image: 'https://i.ibb.co/zn6kFFd/coll-1.png' },
    { key: 'Office', label: 'Office', image: 'https://i.ibb.co/QHfdWDJ/offi.png' },
    { key: 'Party', label: 'Party', image: 'https://i.ibb.co/Brn6NPh/party-1.png' },
    { key: 'Beach', label: 'Beach', image: 'https://i.ibb.co/Z2XXLWW/beach.png' },
    { key: 'Casual', label: 'Casual', image: 'https://i.ibb.co/WvbftCD/casu.png' },
    { key: 'Formal', label: 'Formal', image: 'https://i.ibb.co/QpbYFMH/foru.png' },

 ];

  const colorOptions = [
    'Red',
    'Blue',
    'Green',
    'Yellow',
    'Black',
    'White',
    'Pink',
    'Gray',
    'Purple',
    'Orange',
    'Brown',
    'Teal',
  ];

  const handleGenderSelect = (gender) => {
    setSex(gender);
    setStep(2);
  };

  const handleShopOutfit = () => {
    // Add your logic for shopping the full outfit here
    console.log('Shopping full outfit...');
  };

  const handleOccasionSelect = (key) => {
    setOccasion(key);
    setStep(3);
  };

  const handleColorSelection = (color) => {
    if (!selectedColors.includes(color)) {
      setSelectedColors([...selectedColors, color]);
    } else {
      setSelectedColors(selectedColors.filter((c) => c !== color));
    }
  };

  const handleCategoryClick = (categoryKey) => {
    // Navigate to the outfit category page based on the categoryKey
    // For example, using React Router:
    // history.push(`/outfits/${categoryKey}`);
    console.log(`Navigating to category: ${categoryKey}`);
  };

  const handleGenerateOutfit = () => {
    console.log("Selected Sex:", sex);
    console.log("Selected Occasion:", occasion);
    const filteredOutfits = {
      top: outfitData.filter(
        (outfit) =>
          outfit.sex === sex &&
          outfit.occasions &&
          outfit.occasions.includes(occasion) &&
          outfit.type === 'Top'
      ),
      bottom: outfitData.filter(
        (outfit) =>
          outfit.sex === sex &&
          outfit.occasions &&
          outfit.occasions.includes(occasion) &&
          outfit.type === 'Bottom'
      ),
      footwear: outfitData.filter(
        (outfit) =>
          outfit.sex === sex &&
          outfit.occasions &&
          outfit.occasions.includes(occasion) &&
          outfit.type === 'Footwear'
      ),
      accessories: outfitData.filter(
        (outfit) =>
          outfit.sex === sex &&
          outfit.occasions &&
          outfit.occasions.includes(occasion) &&
          outfit.type === 'Accessories'
      ),
    };
    console.log("Filtered Outfits:", JSON.stringify(filteredOutfits, null, 2));
    console.log("Filtered Outfits:", filteredOutfits);

    const shuffledOutfits = {
      top: shuffle(filteredOutfits.top),
      bottom: shuffle(filteredOutfits.bottom),
      footwear: shuffle(filteredOutfits.footwear),
      accessories: shuffle(filteredOutfits.accessories),
    };


    setOutfitSuggestions(shuffledOutfits);
    const selectedOutfitData = {
      top: shuffledOutfits.top[0] || null,
      bottom: shuffledOutfits.bottom[0] || null,
      footwear: shuffledOutfits.footwear[0] || null,
      accessories: shuffledOutfits.accessories[0] || null,
    };

    console.log("Selected Outfit:", selectedOutfitData);

    setSelectedOutfit(selectedOutfitData);
    setStep(4);
  };

  
  const handleOutfitClick = (url) => {
    window.open(url, '_blank');
  };
  

  const handleGenerateNext = () => {
    const nextOutfit = {};

    Object.keys(outfitSuggestions).forEach((category) => {
      const currentIndex = outfitSuggestions[category].findIndex(
        (outfit) => outfit.id === selectedOutfit[category]?.id
      );
      const nextIndex = (currentIndex + 1) % outfitSuggestions[category].length;
      nextOutfit[category] = outfitSuggestions[category][nextIndex];
    });

    setSelectedOutfit(nextOutfit);
  };

  return (
    <Root>

      <Main>
        <Routes>
          <Route
            path="/"
            element={
              <Container style={{ marginTop: 64 }}>
                {step === 1 && (
  <>
    <Typography variant="h2" align="center" gutterBottom>
      AI Outfit Generator
    </Typography>
    <Typography variant="subtitle1" align="center" gutterBottom>
      Select your outfit from 100,000 AI outfit ideas.
    </Typography>
    <Grid container spacing={4} justify="center" alignItems="center">
      <Grid item>
        <GenderButton
          variant="contained"
          color="primary"
          startIcon={<Avatar src="https://i.ibb.co/H7xVbGL/me.png" />}
          onClick={() => handleGenderSelect('Male')}
        >
          Male
        </GenderButton>
      </Grid>
      <Grid item>
        <GenderButton
          variant="contained"
          color="secondary"
          startIcon={<Avatar src="https://i.ibb.co/zPCyWzk/me-1.png" />}
          onClick={() => handleGenderSelect('Female')}
        >
          Female
        </GenderButton>
      </Grid>
    </Grid>
    <Grid container spacing={4} style={{ marginTop: 32 }}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant="h5" gutterBottom>
              Welcome to AI Outfit Generator
            </Typography>
            <Typography variant="body1" paragraph>
              We have 100,000 outfit ideas for you to choose from. You don't need any other app or website for outfit inspiration. Our collection features the world's most famous brands, ensuring you have access to the latest fashion trends and styles. Whether you're looking for casual outfits, formal attire, or something in between, our AI-powered outfit generator has got you covered. Start exploring now and discover your perfect look!
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4" gutterBottom>
          How It Works
        </Typography>
        <Typography variant="body1" paragraph>
          Step 1: Choose male or female.
        </Typography>
        <Typography variant="body1" paragraph>
          Step 2: Select the occasion for your outfit.
        </Typography>
        <Typography variant="body1" paragraph>
          Step 3: Pick your preferred colors.
        </Typography>
        <Typography variant="body1" paragraph>
          Step 4: Generate outfit suggestions and browse through the options.
        </Typography>
        <Typography variant="body1" paragraph>
          Step 5: Click the link to buy the outfit you like.
        </Typography>
      </Grid>
    </Grid>
    <Typography variant="h2" align="center" gutterBottom>
      AI Outfit Ideas Features
    </Typography>
    <Container maxWidth="md">
      <Typography component="ul">
        <Typography component="li" variant="body1">
          Vast collection of 100,000+ outfit ideas.
        </Typography>
        <Typography component="li" variant="body1">
          AI-powered personalized outfit suggestions.
        </Typography>
        <Typography component="li" variant="body1">
          Latest fashion trends and styles from top brands.
        </Typography>
        <Typography component="li" variant="body1">
          Ability to shop the outfits directly.
        </Typography>
      </Typography>
    </Container>
    <Typography variant="h2" align="center" gutterBottom>
      AI Outfit Generator for Men
    </Typography>
    <Container maxWidth="md">
      <Typography variant="body1" paragraph>
        Discover the perfect outfit for any occasion with our AI-powered outfit generator for men. Explore a vast collection of outfit ideas curated from top fashion brands, featuring the latest trends in casual, formal, and athleisure wear. From office attire to party outfits, we've got you covered with personalized suggestions tailored to your style preferences.
      </Typography>
      <Typography variant="body1" paragraph>
        Our outfit categories include Party, Beach, Wedding, Trekking, College, Office, Formal Events, Outdoor Concerts, Brunch, and Gym. Additionally, you can refine your search by selecting your preferred colors and letting our AI generate outfit combinations that perfectly match your taste.
      </Typography>
    </Container>
    <Typography variant="h3" align="center" gutterBottom>
      AI Outfit Generator for Women
    </Typography>
    <Container maxWidth="md">
      <Typography variant="body1" paragraph>
        Elevate your style game with our AI-powered outfit generator for women. Explore a curated collection of outfit ideas ranging from casual to formal, featuring the latest fashion trends from renowned brands. Whether you're dressing for college, the office, a party, or a special occasion like a wedding or date night, we've got the perfect outfit suggestions for you.
      </Typography>
      <Typography variant="body1" paragraph>
        Our outfit categories include College, Office, Party, Beach, Wedding, Nightclub, Brunch, Maternity, Date Night, and Baby Shower. Simply select your preferred occasion, choose your favorite colors, and let our AI generate stunning outfit combinations that will make you stand out in any crowd.
      </Typography>
    </Container>
    <Typography variant="h2" align="center" gutterBottom>
      FAQ
    </Typography>
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Q: How does the AI Outfit Generator work?
          </Typography>
          <Typography variant="body1" paragraph>
            Our AI Outfit Generator uses advanced algorithms to analyze your preferences, such as occasion, gender, and color choices, and suggests outfit combinations from our vast collection of over 100,000 outfit ideas.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Q: Where do the outfit ideas come from?
          </Typography>
          <Typography variant="body1" paragraph>
            Our outfit ideas are curated from top fashion brands, ensuring you have access to the latest trends and styles. We constantly update our collection to provide you with fresh and stylish options.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Q: Can I shop the outfits directly?
          </Typography>
          <Typography variant="body1" paragraph>
            Yes! With our AI Outfit Generator, you can directly shop the outfit pieces by clicking on the provided links. These links will take you to the respective brand's website or an affiliated online store.
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Q: How often is the AI Outfit Generator updated?
          </Typography>
          <Typography variant="body1" paragraph>
            We regularly update our AI Outfit Generator with new outfit ideas and the latest fashion trends. Our team constantly curates new collections from leading brands to ensure you have access to the freshest styles.
          </Typography>

          <Typography variant="h6" gutterBottom>
            Q: Can I save my favorite outfits?
          </Typography>
          <Typography variant="body1" paragraph>
            At the moment, our AI Outfit Generator does not have a feature to save favorite outfits. However, we are working on implementing this functionality in the future to enhance your user experience.
          </Typography>
        </Grid>
      </Grid>
    </Container>
    <Divider style={{ marginTop: 32, marginBottom: 32 }} />
  </>
)}
                {step === 2 && (
                  <Grid container spacing={2} justify="center">
                    {(sex === 'Male' ? maleOccasions : femaleOccasions).map((occ) => (
                      <Grid item key={occ.key}>
                        <OccasionCard onClick={() => handleOccasionSelect(occ.key)}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={occ.image}
                            alt={occ.label}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                              {occ.label}
                            </Typography>
                          </CardContent>
                        </OccasionCard>
                      </Grid>
                    ))}
                  </Grid>
                )}

                {step === 3 && (
                  <Grid container spacing={2} justify="center" alignItems="center">
                    {colorOptions.map((color) => (
                      <Grid item key={color}>
                        <div
                          style={{
                            backgroundColor: color,
                            width: 50,
                            height: 50,
                            cursor: 'pointer',
                            border: selectedColors.includes(color) ? '2px solid black' : 'none',
                          }}
                          onClick={() => handleColorSelection(color)}
                        />
                      </Grid>
                    ))}
                    <Grid item>
                      <Button variant="contained" color="primary" onClick={handleGenerateOutfit}>
                        Show Outfits
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {step === 4 && (
                  <>
                    <Grid container spacing={2}>
                      {outfitSuggestions.top && outfitSuggestions.top.length > 0 ? (
                        selectedOutfit ? (
                          <>
                            <Grid item xs={6} sm={3}>
  <OutfitCard onClick={() => handleOutfitClick(selectedOutfit.top.url)}>
    <OutfitImage src={selectedOutfit.top.image} alt="Top" />
    <OutfitOverlay>Try this outfit</OutfitOverlay>
    <CardContent>
      <Typography variant="h6">Top</Typography>
    </CardContent>
  </OutfitCard>
</Grid>
<Grid item xs={6} sm={3}>
  <OutfitCard onClick={() => handleOutfitClick(selectedOutfit.bottom.url)}>
    <OutfitImage src={selectedOutfit.bottom.image} alt="Bottom" />
    <OutfitOverlay>Try this outfit</OutfitOverlay>
    <CardContent>
      <Typography variant="h6">Bottom</Typography>
    </CardContent>
  </OutfitCard>
</Grid>
<Grid item xs={6} sm={3}>
  <OutfitCard onClick={() => handleOutfitClick(selectedOutfit.footwear.url)}>
    <OutfitImage src={selectedOutfit.footwear.image} alt="Footwear" />
    <OutfitOverlay>Try this outfit</OutfitOverlay>
    <CardContent>
      <Typography variant="h6">Footwear</Typography>
    </CardContent>
  </OutfitCard>
</Grid>
<Grid item xs={6} sm={3}>
  <OutfitCard onClick={() => handleOutfitClick(selectedOutfit.accessories.url)}>
    <OutfitImage src={selectedOutfit.accessories.image} alt="Accessories" />
    <OutfitOverlay>Try this outfit</OutfitOverlay>
    <CardContent>
      <Typography variant="h6">Accessories</Typography>
    </CardContent>
  </OutfitCard>
</Grid>
                          </>
                        ) : (
                          <Typography
                            variant="h6"
                            component="div"
                            style={{ margin: 16, width: '100%', textAlign: 'center' }}
                          >
                            No outfit selected. Please click "Generate Next Outfit" to view an outfit.
                          </Typography>
                        )
                      ) : (
                        <Typography
                          variant="h6"
                          component="div"
                          style={{ margin: 16, width: '100%', textAlign: 'center' }}
                        >
                          No outfits available for these selections. Please select other options.
                        </Typography>
                      )}
                    </Grid>
                    <Grid container justify="center" style={{ marginTop: 16 }}>
                      <GenerateButton
                        variant="contained"
                        color="primary"
                        onClick={handleGenerateNext}
                      >
                        Generate Next Outfit
                      </GenerateButton>
                      <ResetButton
                        variant="contained"
                        color="secondary"
                        onClick={() => setStep(1)}
                      >
                        Reset
                      </ResetButton>
                    </Grid>
                    <Grid container spacing={4} style={{ marginTop: 32 }}>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardHeader
                            title="Outfit Details"
                            titleTypographyProps={{ variant: 'h4', align: 'center' }}
                            style={{ backgroundColor: '#f5f5f5' }}
                          />
                          <CardContent>
                            <List>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar src={selectedOutfit.top.image} />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={selectedOutfit.top.name}
                                  secondary={`Price: $${selectedOutfit.top.price}`}
                                />
                              </ListItem>
                              <Divider variant="inset" component="li" />
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar src={selectedOutfit.bottom.image} />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={selectedOutfit.bottom.name}
                                  secondary={`Price: $${selectedOutfit.bottom.price}`}
                                />
                              </ListItem>
                              <Divider variant="inset" component="li" />
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar src={selectedOutfit.footwear.image} />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={selectedOutfit.footwear.name}
                                  secondary={`Price: $${selectedOutfit.footwear.price}`}
                                />
                              </ListItem>
                              <Divider variant="inset" component="li" />
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar src={selectedOutfit.accessories.image} />
                                </ListItemAvatar>
                                <ListItemText
                                  primary={selectedOutfit.accessories.name}
                                  secondary={`Price: $${selectedOutfit.accessories.price}`}
                                />
                              </ListItem>
                            </List>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Card>
                          <CardHeader
                            title="Styling Tips"
                            titleTypographyProps={{ variant: 'h4', align: 'center' }}
                            style={{ backgroundColor: '#e0f2f1' }}
                          />
                          <CardContent>
                            <Typography variant="body1" paragraph>
                              The {selectedOutfit.top.name} from {selectedOutfit.top.brand} is a versatile piece that
                              pairs well with various bottoms. For a party outfit, consider wearing it with{' '}
                              {selectedOutfit.bottom.name} to create a stylish and trendy look.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              To complete the outfit, we've included {selectedOutfit.footwear.name} and{' '}
                              {selectedOutfit.accessories.name}. These accessories will elevate your overall appearance
                              and make you stand out at any party or event.
                            </Typography>
                            <Typography variant="body1" paragraph>
                              Remember, fashion is all about expressing your personal style and feeling confident in
                              what you wear. Don't be afraid to experiment with different combinations and find what
                              works best for you!
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>

                    <Grid container spacing={4} style={{ marginTop: 32 }}>
                      <Grid item xs={12}>
                        <Card>
                          <CardHeader
                            title="Shop the Look"
                            titleTypographyProps={{ variant: 'h4', align: 'center' }}
                            style={{ backgroundColor: '#f5f5f5' }}
                          />
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Grid container alignItems="center" spacing={2}>
                                  <Grid item>
                                    <Avatar
                                      alt="Top"
                                      src={selectedOutfit.top.image}
                                      style={{ width: 80, height: 80 }}
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="subtitle1">{selectedOutfit.top.name}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      onClick={() => handleOutfitClick(selectedOutfit.top.url)}
                                    >
                                      Try This
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Grid container alignItems="center" spacing={2}>
                                  <Grid item>
                                    <Avatar
                                      alt="Bottom"
                                      src={selectedOutfit.bottom.image}
                                      style={{ width: 80, height: 80 }}
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="subtitle1">{selectedOutfit.bottom.name}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      onClick={() => handleOutfitClick(selectedOutfit.bottom.url)}
                                    >
                                      Try This
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Grid container alignItems="center" spacing={2}>
                                  <Grid item>
                                    <Avatar
                                      alt="Footwear"
                                      src={selectedOutfit.footwear.image}
                                      style={{ width: 80, height: 80 }}
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="subtitle1">{selectedOutfit.footwear.name}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      onClick={() => handleOutfitClick(selectedOutfit.footwear.url)}
                                    >
                                      Try This
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Grid container alignItems="center" spacing={2}>
                                  <Grid item>
                                    <Avatar
                                      alt="Accessories"
                                      src={selectedOutfit.accessories.image}
                                      style={{ width: 80, height: 80 }}
                                    />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant="subtitle1">{selectedOutfit.accessories.name}</Typography>
                                  </Grid>
                                  <Grid item>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      size="small"
                                      onClick={() => handleOutfitClick(selectedOutfit.accessories.url)}
                                    >
                                      Try This
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <Divider variant="middle" />
                          <CardActions></CardActions>
                        </Card>
                      </Grid>
                    </Grid>

                    <Grid container spacing={4} style={{ marginTop: 32 }}>
                      <Grid item xs={12}>
                        <Typography variant="h4" align="center" gutterBottom>
                          Related Outfit Categories
                        </Typography>
                      </Grid>
                      {relatedCategories.map((category) => (
                        <Grid item xs={4} sm={2} key={category.key}>
                          <Card onClick={() => handleCategoryClick(category.key)}>
                            <CardContent
                              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                            >
                              <Avatar
                                alt={category.label}
                                src={category.image}
                                style={{ width: 80, height: 80, marginBottom: 8 }}
                              />
                              <Typography variant="subtitle1" align="center">
                                {category.label}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>

                    <Divider style={{ marginTop: 32, marginBottom: 32 }} />
                  </>
                )}
              </Container>
            }
          />
        </Routes>
      </Main>
      <Footer />
    </Root>
  );
};

export default App;