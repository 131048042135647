import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Grid, Typography, Link } from '@mui/material';

const FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: theme.spacing(4),
  marginTop: 'auto',
}));

const Footer = () => {
  return (
    <FooterContainer>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">AI Outfit Generator</Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">
              <Link href="/about">About Us</Link>
            </Typography>
            <Typography variant="body1">
              <Link href="/privacy">Privacy Policy</Link>
            </Typography>
            <Typography variant="body1">
              <Link href="/partnership">Partnership</Link>
            </Typography>
            <Typography variant="body1">
              <Link href="/contact">Contact Us</Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2" align="right">
              © 2024 AI Outfit Generator. All rights reserved. Powered by Breadbyte Marketing.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </FooterContainer>
  );
};

export default Footer;