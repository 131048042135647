import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';

const HeaderAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const HeaderTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
}));

const HeaderMenuButton = styled(IconButton)(({ theme }) => ({
  marginLeft: 'auto', // Add this line
}));

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <HeaderAppBar position="static">
      <Toolbar>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <HeaderTitle variant="h6">AI Outfit Generator</HeaderTitle>
        </Link>
        <HeaderMenuButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </HeaderMenuButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <Link to="/about" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem onClick={handleMenuClose}>About Us</MenuItem>
          </Link>
          <Link to="/privacy" style={{ textDecoration: 'none', color: 'inherit' }}>
            <MenuItem onClick={handleMenuClose}>Privacy</MenuItem>
          </Link>
          <Link
            to="/partnership"
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <MenuItem onClick={handleMenuClose}>Partnership</MenuItem>
          </Link>
        </Menu>
      </Toolbar>
    </HeaderAppBar>
  );
};

export default Header;