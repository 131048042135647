import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

const AboutContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const AboutHeading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const AboutParagraph = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const About = () => {
  return (
    <AboutContainer maxWidth="md">
      <AboutHeading variant="h4" align="center">
        About AI Outfit Generator
      </AboutHeading>
      <AboutParagraph variant="body1">
        We're a team of tech enthusiasts and fashion lovers who believe that everyone should have access to personalized style recommendations. With the rise of artificial intelligence and our passion for fashion, we launched this app to help people discover their perfect outfits with ease.
      </AboutParagraph>
      <AboutParagraph variant="body1">
        Our AI-powered outfit generator curates a vast collection of over 100,000 outfit ideas from top fashion brands, ensuring you have access to the latest trends and styles. Whether you're dressing for a casual day out, a formal event, or a special occasion, our app provides personalized outfit suggestions tailored to your preferences, occasion, and style.
      </AboutParagraph>
      <AboutParagraph variant="body1">
        At AI Outfit Generator, we believe that fashion should be accessible and enjoyable for everyone. Our goal is to empower you to express your unique style with confidence, while saving you time and effort in finding the perfect outfit. With just a few clicks, you can explore a world of fashion possibilities and shop your favorite looks directly from our app.
      </AboutParagraph>
    </AboutContainer>
  );
};

export default About;