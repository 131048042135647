import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, TextField, Button } from '@mui/material';

const ContactContainer = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
}));

const ContactForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2),
}));

const ContactTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
}));

const ContactButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <ContactContainer>
      <Typography variant="h4" align="center" gutterBottom>
        Contact Us
      </Typography>
      <ContactForm onSubmit={handleSubmit}>
        <ContactTextField label="Name" variant="outlined" required />
        <ContactTextField label="Email" variant="outlined" type="email" required />
        <ContactTextField label="Message" variant="outlined" multiline rows={4} required />
        <ContactButton type="submit" variant="contained" color="primary">
          Send
        </ContactButton>
      </ContactForm>
    </ContactContainer>
  );
};

export default Contact;