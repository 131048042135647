import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography } from '@mui/material';

const PrivacyContainer = styled(Container)(({ theme }) => ({
  maxWidth: 'md',
}));

const Privacy = () => {
  return (
    <PrivacyContainer>
      <Typography variant="h4" align="center" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="h6" gutterBottom>
        Information We Collect
      </Typography>
      <Typography variant="body1" paragraph>
        We may collect personal information such as your name, email address, and preferences when you interact with our website. We also collect non-personal information such as your browser type, IP address, and referring pages.
      </Typography>
      <Typography variant="h6" gutterBottom>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We use your information to provide and improve our services, personalize your experience, and communicate with you about our products and promotions. We may also use aggregated data for research and analytics purposes.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Sharing Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We do not sell, trade, or rent your personal information to third parties. We may share your information with trusted partners who assist us in operating our website and conducting our business.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Data Security
      </Typography>
      <Typography variant="body1" paragraph>
        We implement reasonable security measures to protect your information from unauthorized access, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.
      </Typography>
      <Typography variant="h6" gutterBottom>
        Your Choices
      </Typography>
      <Typography variant="body1" paragraph>
        You may opt out of receiving promotional emails from us by following the unsubscribe instructions in those emails. You may also update your preferences or delete your account by contacting us directly.
      </Typography>
    </PrivacyContainer>
  );
};

export default Privacy;