import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import outfitData from '../../data/outfitData';

const Root = styled('div')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

const OutfitGrid = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const OutfitCard = styled(Card)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
}));

const OutfitImage = styled('div')(({ theme }) => ({
  height: 0,
  paddingTop: '100%',
}));

const OutfitTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(1),
}));

const GenerateNextButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const CategoryPage = () => {
  const [selectedOutfit, setSelectedOutfit] = useState(null);

  const categoryOutfits = outfitData.filter(
    (outfit) => outfit.sex === 'Male' && outfit.occasion === 'Party'
  );

  const handleGenerateOutfit = () => {
    const randomIndex = Math.floor(Math.random() * categoryOutfits.length);
    setSelectedOutfit(categoryOutfits[randomIndex]);
  };

  return (
    <Container component={Root}>
      <Typography variant="h4" align="center" gutterBottom>
        CATEGORY_NAME Outfits for Men
      </Typography>
      <Grid container spacing={4} component={OutfitGrid}>
        {selectedOutfit ? (
          <>
            <Grid item xs={12} sm={6} md={3}>
              <OutfitCard>
                <CardMedia
                  component={OutfitImage}
                  image={selectedOutfit.top.image}
                  title="Top"
                />
                <CardContent>
                  <OutfitTitle variant="h6">Top</OutfitTitle>
                </CardContent>
              </OutfitCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <OutfitCard>
                <CardMedia
                  component={OutfitImage}
                  image={selectedOutfit.bottom.image}
                  title="Bottom"
                />
                <CardContent>
                  <OutfitTitle variant="h6">Bottom</OutfitTitle>
                </CardContent>
              </OutfitCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <OutfitCard>
                <CardMedia
                  component={OutfitImage}
                  image={selectedOutfit.footwear.image}
                  title="Footwear"
                />
                <CardContent>
                  <OutfitTitle variant="h6">Footwear</OutfitTitle>
                </CardContent>
              </OutfitCard>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <OutfitCard>
                <CardMedia
                  component={OutfitImage}
                  image={selectedOutfit.accessories.image}
                  title="Accessories"
                />
                <CardContent>
                  <OutfitTitle variant="h6">Accessories</OutfitTitle>
                </CardContent>
              </OutfitCard>
            </Grid>
          </>
        ) : (
          <Typography variant="h6" align="center" style={{ width: '100%' }}>
            Click "Generate Outfit" to see a suggested outfit for CATEGORY_NAME.
          </Typography>
        )}
      </Grid>
      <Grid container justify="center">
        <GenerateNextButton
          variant="contained"
          color="primary"
          onClick={handleGenerateOutfit}
        >
          Generate Outfit
        </GenerateNextButton>
      </Grid>
    </Container>
  );
};

export default CategoryPage;